import React from 'react';
import logo from './logo.svg';

import './App.css';
import generate from 'meaningful-string';

function App() {
  let options = {
    "numberUpto": 1000,
    "joinBy": '-'
  };
  let arrayName = generate.meaningful(options).toLocaleLowerCase();

  return (
    <div className="App">
      <div className="logo">
        <div className="img">
          <img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/logo-cropped.png" />
        </div>

        <div className="pandas">pandas</div>
        <div className="in-the">in the</div>
        <div className="cloud">cloud</div>
      </div>

      <div className="content">
        <div className="one-line">
          <div className="header">Change <b>one line</b> of code to harness the<br/> power of <b>the cloud</b> for pandas</div>

          <div className="diff">
            <div className="remove">
              <span className="minus">-</span> import <b>pandas</b> as pd
            </div>
            <div className="add">
              <span className="plus">+</span> import <b>cloudpy.pandas</b> as pd
            </div>
          </div>

          <div className="blurb">
            <p className="drop-in"><code>cloudpy.pandas</code> is a drop-in replacement for <code>pandas</code> to handle large datasets that don't fit in your local memory and easily access data across multiple machines, all with no changes to your tooling or workflow.</p>
          </div>

          <div className="main-img">
            <img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/local-cloud.png" />
          </div>
        </div>

        <div className="details">
          <div className="how-it-works">
            <div className="how-it-works-header">
              How it works
            </div>

            <div className="blurbs">
              <div className="blurb">
                <div className="img"><img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/002-api.svg" /></div>
                <div className="text">
                  <div className="header">Change one line of code</div>
                  <div className="body">
                    Using cloudpy is easy. All you change is one line of code. Instead of importing <code>pandas as pd</code>, you import <code>cloudpy.pandas as pd</code>. After this change, you'll be able to store 100's of GB dataframes in the cloud. Unlike other tools, nothing else changes. You can continue using the pandas interface you're familiar with, just like before.
                  </div>
                </div>
              </div>

              <div className="blurb">
                <div className="img"><img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/003-data-transfer.svg" /></div>
                <div className="text">
                  <div className="header">Store dataframes in the cloud</div>
                  <div className="body">
                    How does this all work? The cloudpy package sends your local data to a cloud server, and proxies all your local function calls to the remote sever. This means you're still using the pandas library, but instead of executing computations locally, it happens in the cloud.
                  </div>
                </div>
              </div>

              <div className="blurb">
                <div className="img"><img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/001-blockchain.svg" /></div>
                <div className="text">
                  <div className="header">Load data that exceeds local memory</div>
                  <div className="body">
                    Offloading your data handling to a cloud server means you have access to lots and lots of memory, potentially 100's of GB. You'll be able to process dataframes that would result in an out of memory exception if you tried to load them on your local machine.
                  </div>
                </div>
              </div>

              <div className="blurb">
                <div className="img"><img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/workflow.png" /></div>
                <div className="text">
                  <div className="header">Keep your existing workflow</div>
                  <div className="body">
                    With cloudpy, you don't have to make any changes to how you use pandas currently. You don't need to switch to a hosted Jupyter notebook, figure out how to use a new library or database, or learn about AWS configuration. Keep focusing on datascience, and let cloudpy handle the dev ops.
                  </div>
                </div>
              </div>

              <div className="blurb">
                <div className="img"><img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/004-cloud-computing.svg" /></div>
                <div className="text">
                  <div className="header">Easily access your data across different machines</div>
                  <div className="body">If you work on multiple machines, using cloudpy makes it easy to access dataframes from each different clients. Upload your data once to the cloud, and instantly access it for subsequent analysis, regardless of where you're connecting from.</div>
                </div>
              </div>
            </div>
          </div>

          <div className="try-it">
            <div className="try-header">
              Try it in 30 seconds
            </div>

            <div className="try-it-inner">
              <ul>
                <li>
                  <div className="step-header">1. Install the package</div>
                  <code className="snippet terminal">
                    pip install cloudpyio
                  </code>
                </li>

                <li>
                  <div className="step-header">2. Start with normal <code>pandas</code></div>
                  <p>First, let's try it with normal <code>pandas</code> so we can compare memory usage in the next step.</p>

                  <code className="snippet">import cloudpy<br/>
                    import pandas as pd<br/>
                    import numpy as np<br/>
                    <br/>
                    start_mem = cloudpy.memory_info().rss<br/>
                    df = pd.DataFrame(np.random.randint(0, 100, size=(10000, 1000)), columns=list('A' * 1000))<br/>
                    used_mem = cloudpy.memory_info().rss - start_mem<br/>
                    <br/>
                    print('The dataframe used %.1fMB of memory' % (used_mem/1e6))<br/>
                  </code>

                  <p>Running this will print the memory used by the <code>pandas</code> dataframe, about <b>80MB</b>.</p>
                </li>

                <li>
                  <div className="step-header">3. Wield the power of the cloud</div>
                  <p>Let's use the cloud to bring down the memory usage. Use the drop-in replacements for <code>numpy</code> and <code>pandas</code>, and run the same code again.</p>
                  <code className="snippet">import cloudpy<br/>
                    <span className="green">import cloudpy.pandas as pd</span><br/>
                    <span className="green">import cloudpy.numpy as np</span><br/>
                    <br/>
                    start_mem = cloudpy.memory_info().rss<br/>
                    df = pd.DataFrame(np.random.randint(0, 100, size=(10000, 1000)), columns=list('A' * 1000))<br/>
                    used_mem = cloudpy.memory_info().rss - start_mem<br/>
                    <br/>
                    print('The dataframe used %.1fMB of memory' % (used_mem/1e6))<br/>
                  </code>

                  <p>The memory usage has dropped below <b>4MB</b>. No matter how big the dataframe gets, <code>cloudpy.pandas</code> doesn't require any more local memory than that.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="title">Getting started</div>

          <p>
            First, lets install the <code>cloudpyio</code> package from pypi:
          </p>

          <code className="terminal">
            pip install cloudpyio
          </code>

          <p>
            Next, let's make a new python program, and try out the package. To get started, let's import the package and play with some data in the cloud.
          </p>

          <code className="block">
            import cloudpy<br/>
            import cloudpy.numpy as np<br/>
            import cloudpy.pandas as pd<br/>
            <br/>
            arr = np.arange(4000000).reshape(4000, 1000)<br/>
          </code>

          <p>
            The program above has created an array in the cloud <code>arr</code>. This array has a shapes of <code>(4000, 1000)</code>. Normally, this array would use 32MB of local memory, but because we're using cloudpy, the memory consumption is much lower. We can confirm that by comparing process memory before and after we create the array.
          </p>

          <code className="block">
            before = cloudpy.memory_info().rss<br/>
            arr = np.arange(4000000).reshape(4000, 1000)<br/>
            after = cloudpy.memory_info().rss<br/>
            <br/>
            increase = after - before<br/>
            print('The array used %.1fMB of memory' % (increase/1e6))<br/>
          </code>

          <p>
            The local memory usage is under 4MB, because the 32MB array is stored in the cloud.
          </p>

          <p>
            Of course, we can create pandas dataframes, not just numpy arrays. Let's make some random test data, and the compute the average of one of the columns.
          </p>

          <code className="block">
            import cloudpy.numpy as np<br/>
            import cloudpy.pandas as pd<br/>
            <br/>
            df = pd.DataFrame(np.random.randint(0, 100, size=(100, 1000)), columns=['column_%s' % i for i in range(1000)])<br/>
            print(df)
          </code>

          <p>
            We can do computations on this dataframe as usual:
          </p>

          <code className="block">
            import cloudpy.numpy as np<br/>
            import cloudpy.pandas as pd<br/>
            <br/>
            df = pd.DataFrame(np.random.randint(0, 100, size=(100, 1000)), columns=['column_%s' % i for i in range(1000)])<br/>
            print(df)<br/>
            print(df['column_12'].mean())<br/>
            print(df.where(df['column_12'] > 50)['column_12'].mean())
          </code>

          <p>
            If you load data from a file, such as a .csv, cloudpy will upload it the first time it sees it. Subsequent loads of the same file will not require an upload.
          </p>

          <p>
            First, get the data.
          </p>

          <code className="terminal">
            wget https://cloudpy.s3-us-west-1.amazonaws.com/telecom_churn.csv
          </code>

          <p>
            Then, load it in the cloud.
          </p>

          <code className="block">
            import cloudpy.numpy as np<br/>
            import cloudpy.pandas as pd<br/>
            <br/>
            df = pd.read_csv('./telecom_churn.csv')<br/>
            print(df.head())<br/>
            print(df.where(df['area code'] == 415)['total intl charge'].mean())
          </code>

          <p>
            You can also load and save data to the cloud. This let's you easily access data across multiple machines. Below is a snippet which loads an example public dataset.
          </p>


          <code className="block">
            import cloudpy<br/>
            import cloudpy.pandas as pd<br/>
            <br/>
            df = cloudpy.load('public/MSFT')<br/>
            print(df.head())<br/>
            <br/>
            # Saving requires an API key.<br/>
            # Contact hello@cloudpy.io to get access.<br/>
            cloudpy.save(df.head(), 'MSFT_head')<br/>
          </code>

          <p>
            That's all there is to it! Using <code>cloudpy.pandas</code> is just like using a normal <code>pandas</code> dataframe.
          </p>

        </div>
        

        <div className="section">
          <div className="title">Q &amp; A</div>

          <div className="q-a">
            <div className="q">What is the project status?</div>
            <div className="a">Cloudpy is in a beta state right now.</div>
          </div>

          <div className="q-a">
            <div className="q">How much data can cloudpy handle?</div>
            <div className="a">Our free instances go up to 128MB, and are suitable for trying it out and kicking the tires. If you have larger data than that, please contact <a href="mailto:hello@cloudpy.io?subject=cloudpy large datasets">hello@cloudpy.io</a>. We'd love to chat and make sure cloudpy works well for use case.</div>
          </div>

          {/*
          <div className="q-a">
            <div className="q">Who's behind this project?</div>
            <div className="a">My name is Marcell, and I created cloudpy to help data scientists work with large data sets. My goal is to make it so data scientists never think about memory or CPU again! You can get in touch with me by emailing <a href="mailto:hello@cloudpy.io?subject=cloudpy">hello@cloudpy.io</a>.</div>
              </div>
              */}

        </div>

        <div className="section section-pricing">
          <div className="title">Pricing</div>
          <div className="pricing">
            <table>
              <tr>
                <th></th>
                {/* <th>Sandbox</th> */}
                <th>Free</th>
                <th>Paid</th>
              </tr>

              <tr>
                <td>Max memory</td>
                {/* <td>1MB</td> */}
                <td>128MB</td>
                <td>Up to 512GB</td>
              </tr>

              <tr>
                <td>Session</td>
                <td>
                  Ephemeral
                  <div><small>Data is evicted periodically based on server loads</small></div>
                </td>
                {/* <td>Data is persisted for 24 hours</td> */}
                <td>Data is persisted</td>
              </tr>

              <tr>
                <td>Pricing</td>
                <td>Free</td>
                {/*
                    <td>
                    Free<br/>
                    <small>Requires confirmed email</small>
                    </td>
                  */}
                    <td>
                      $0.01/hr per GB<br/>
                      <small>Only active usage is billed</small>
                    </td>
              </tr>
              <tr>
                <td>Access</td>
                <td>Just <code>pip install</code> it!</td>
                <td><button><a href="mailto:hello@cloudpy.io?subject=cloudpy large datasets">Get Beta Access</a></button></td>
              </tr>

            </table>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="img">
          <img src="https://cloudpy.s3-us-west-1.amazonaws.com/static/logo-text.png" />
        </div>
      </div>
    </div>
  );
}

export default App;
